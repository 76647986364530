import React, { useState } from "react"

//Redux
import { useSelector } from "react-redux"

//Components
import Section from "../../components/Tools/Section/Section"
import FeatureContainer from "../../components/Tools/FeatureContainer/FeatureContainer"
import CommunicationBar from "../../components/Tools/CommunicationBar/CommunicationBar"

/* MEDIA */
// Screen Shots
import muniAppCards from "../../assets/company/muni-app-cards.webp"

// Icons
import configurationIcon from "../../assets/company/configuration-icon.svg"
import atmICon from "../../assets/company/atm-icon.svg"
import worldWideICon from "../../assets/company/world-wide-icon.svg"
/* */

// Local Data
import companyCardsData from "../../localized/companyCardsLocalized.json"
import communicationBarData from "../../localized/communicationBarLocalized.json"
import Layout from "../../components/Layout"

const CompanyCards = () => {
  const currentLanguage = useSelector(state => state.changeLanguageReducer)

  const [signUpEmail, setSignUpEmail] = useState("")

  const companyCardsFeaturesData = [
    {
      cardIcon: configurationIcon,
      cardTitle: companyCardsData.configurableLimits?.[currentLanguage],
      cardDescription: companyCardsData.setLimits?.[currentLanguage],
    },
    {
      cardIcon: atmICon,
      cardTitle: companyCardsData.atmWithdrawals?.[currentLanguage],
      cardDescription: companyCardsData.carpeDiem?.[currentLanguage],
    },
    {
      cardIcon: worldWideICon,
      cardTitle: companyCardsData.acceptedEverywhere?.[currentLanguage],
      cardDescription: companyCardsData.thousandsOfLocations?.[currentLanguage],
    },
  ]

  // useEffect(() => {
  //   setCurrentLanguage(localStorage.getItem('language'))
  // },[currentLanguage])

  const companyCardsFirstSection = (
    <div className="media_section" id="company-card-first-section">
      <div
        className="wow animate__fadeIn media_section_content"
        id="company-card-media-container"
        data-wow-duration="2s"
      >
        <img
          src={muniAppCards}
          alt="Home mockup"
          className="media_section_content_mobile black_media_shadow"
        />
      </div>
      <div className="section_paragraph">
        <span
          data-wow-duration="2s"
          className="wow animate__fadeInUp section_paragraph_top white_text"
          id="third-section-title"
        >
          {companyCardsData.cardsFromtheFuture?.[currentLanguage]}
        </span>
        <span
          className="wow animate__fadeInLeft section_paragraph_middle white_text"
          data-wow-duration="1s"
          id="third-section-subtitle"
        >
          {companyCardsData.asFlexible?.[currentLanguage]} <br />
          {companyCardsData.asManagable?.[currentLanguage]}
        </span>
        <div
          className="wow animate__fadeInUp section_paragraph_bottom"
          data-wow-duration="1.5s"
        >
          <span className="white_text">
            {companyCardsData.getBusinessCards?.[currentLanguage]}
          </span>
        </div>
      </div>
    </div>
  )

  const companyCardsSecondSection = (
    <div id="company-card-second-section">
      <FeatureContainer data={companyCardsFeaturesData} />
    </div>
  )

  const companyCardsThirdSection = (
    <div id="company-card-third-section">
      <div
        className="communication_bar_container wow animate__fadeIn"
        data-wow-duration="2s"
      >
        <div className="communication_bar_header">
          {`${communicationBarData.beTheFirst?.[currentLanguage]} !`}
        </div>
        <CommunicationBar
          id="company-cards-signup-bar"
          inputValue={signUpEmail}
          changeInputValue={setSignUpEmail}
          navigateLink={`/waitlist/?lang=${currentLanguage}`}
          type="joinWaitlist"
          white={true}
        />
      </div>
    </div>
  )

  return (
    <Layout>
      <div id="company-card">
        <Section
          backgroundColor="white"
          sectionContent={companyCardsFirstSection}
          sectionId="company-card-first-section-wrapper"
        />
        <Section
          backgroundColor="gray"
          sectionContent={companyCardsSecondSection}
          sectionId="company-card-second-section-wrapper"
          otherClassName="px-5"
        />
        <Section
          backgroundColor="gray"
          sectionContent={companyCardsThirdSection}
          sectionId="company-card-third-section-wrapper"
          otherClassName="pt-0"
        />
      </div>
    </Layout>
  )
}

export default CompanyCards
